import React, { useEffect, useLayoutEffect, useState, useRef, memo } from "react";

import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import * as colors from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import axios from "axios";
import TimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import { Login } from "./app-components/Login";
import { NoMeetings } from "./app-components/NoMeetings";
import "./app-components/Participantesview.css";
import { ReloadButton } from "./app-components/ReloadButton";
import { SpinnerComponent } from "./app-components/SpinnerComponent";
// @ts-ignore
import en from "./app-components/timeAgoStrings.json";
import { UnknownError } from "./app-components/UnknownError";
import { NotConnected } from "./app-components/NotConnected";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconEvent from "@mui/icons-material/Event";
import IconLink from "@mui/icons-material/Link";
import IconPlayCircleFilled from "@mui/icons-material/PlayCircleFilled";
import { getCookieForUserAuthentication } from "global/cookies";
import { RestaurantMenu } from "@mui/icons-material";

TimeAgo.addLocale(en);

const PAGE_SIZE = 5;

const companyStyle = {
  fontWeight: "500",
  fontSize: "17px",
  paddingTop: "6px",
  paddingLeft: "0px",
  paddingRight: "0px",
};

const participantStyle = {
  padding: "6px",
};

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

const linkedinLink = (url, full_name, company_name) => {
  if (!url) {
    url = `https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(
      full_name
    )}`;
    if (company_name && company_name !== "Personal") {
      url += encodeURIComponent(" " + company_name);
    }
  }
  return (
    <div
      className="logo-linkedin-small"
      style={{ cursor: "pointer" }}
      // @ts-ignore
      onClick={() => window.open(url)}
    >
      <div className="i-dot"></div>
      <div className="i-body"></div>
      <div className="n-body"></div>
      <div className="n-curve"></div>
      <div className="n-curve-none"></div>
    </div>
  );
};

function stringToColor(string) {
  let randomColors = [
    colors.red[500],
    colors.pink[500],
    colors.purple[500],
    colors.deepPurple[500],
    colors.indigo[500],
    colors.blue[500],
    colors.lightBlue[500],
    colors.cyan[500],
    colors.teal[500],
    colors.green[500],
    colors.lightGreen[500],
    colors.amber[500],
    colors.orange[500],
    colors.deepOrange[500],
    colors.brown[500],
  ];

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  return randomColors[hash % randomColors.length];
}

function stringAvatar(name, logo) {
  if (name === undefined) {
    return;
  }

  let initials = "";
  if (name.length === 0) {
    initials = "X";
  } else {
    initials = name[0];
  }

  let color = stringToColor(name);
  if (logo !== "" && logo !== undefined && logo !== null) {
    // @ts-ignore
    color = "#FFFFFF";
  }

  return {
    sx: {
      bgcolor: color,
    },
    children: initials,
  };
}

const ChosenVars = {
  "--color": "rgba(85, 210, 25, 0.08)",
  "--hover-color": "rgba(85, 210, 25, 0.12)",
};

const defaultVars = {
  "--color": "rgba(0, 0, 0, 0.04)",
  "--hover-color": "rgba(0, 0, 0, 0.04)",
};

const NoMeetingAttendees = () => (
  <TableRow key={`noAttendee`} sx={{ width: "inherit", alignText: "center" }}>
    <TableCell sx={{ ...participantStyle, width: "40px" }}>
      <Tooltip title="N/A" disableInteractive>
        <Avatar sx={{ borderRadius: "50%" }} />
      </Tooltip>
    </TableCell>
    <TableCell
      sx={{ ...participantStyle, minWidth: "100px" }}
      // @ts-ignore
      colSpan="3"
    >
      No attendees
    </TableCell>
  </TableRow>
);

const refreshInterval = 60000 * 5; // Five minutes

const Participantsview = () => {
  const [chosen, setChosen] = useState();
  const [currentInterval, setCurrentInterval] = useState(null);
  const [vars, setVars] = useState(defaultVars);
  const [backendResponse, setBackendResponse] = useState([]);
  const [toggles, setToggles] = useState({});
  const [reloading, setReloading] = useState(false);
  const [relogin, setRelogin] = useState(false);
  const [session, setSession] = useState(null);
  const [email, setEmail] = useState();
  const [pages, setPages] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [meetPalState, setMeetPalState] = useState("loading");

  const [paginationLoading, setPaginationLoading] = useState(false);
  const currentMeetingRef = useRef(null);

  useEffect(() => {
    console.log("first useEffect triggered!");

    setCurrentDate(parseDateToDateStr(new Date()));

    async function getSession() {
      let s = getCookieForUserAuthentication();

      if (s) {
        setSession(s);
        return;
      }
      setMeetPalState("permission_denied");
    }

    getSession();
  }, []);

  async function getMeetings(autoRefresh = false) {
    const searchParams = new URLSearchParams();
    if (autoRefresh) {
      // @ts-ignore
      searchParams.append("auto_refresh", 1);
    } else {
      // @ts-ignore
      searchParams.append("reload", 1);
    }

    searchParams.append("session", session);
    searchParams.append("period", getPeriodFromDate(currentDate));

    axios
      .get(`https://dev.meetpal.co/api/v1/get_participants?${searchParams.toString()}`, {
        headers: {
          MEETPAL_SESSION: session,
        },
      })
      .then((response) => {
        if (
          response.status === 200 &&
          response.data["status"] === "OK" &&
          "results" in response.data
        ) {
          if (response.data["results"].length === 0) {
            setMeetPalState("noMeetings");
          } else {
            setBackendResponse(response.data["results"]);
            setEmail(response.data["email"]);
            setDefaultToggles(response.data["results"]);
            setMeetPalState("loaded");
          }
        } else if (
          response.data["status"] === "ERROR" &&
          (response.data["error"] === "PERMISSION_DENIED" ||
            response.data["error"] === "UNAUTHENTICATED")
        ) {
          setMeetPalState("permission_denied");
        } else {
          setMeetPalState("error");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          setMeetPalState("permission_denied");
        } else if (error.code === "ERR_NETWORK") {
          setMeetPalState("ERR_NETWORK");
        } else {
          setMeetPalState("error");
        }
      })
      .finally(() => {
        setReloading(false);
      });
  }

  function removeAutoRefresh() {
    clearInterval(currentInterval);
    setCurrentInterval(null);
  }

  async function getMeetingsWithSomeone(someoneEmail) {
    setMeetPalState("loading");

    const searchParams = new URLSearchParams();
    if (someoneEmail) {
      searchParams.append("email_to_search", someoneEmail);
    }
    // @ts-ignore
    searchParams.append("reload", 1);

    // clear interval
    removeAutoRefresh();

    await axios
      .get(`https://dev.meetpal.co/api/v1/get_participant_meetings?${searchParams.toString()}`, {
        headers: {
          MEETPAL_SESSION: session,
        },
      })
      .then((response) => {
        if (
          response.status === 200 &&
          response.data["status"] === "OK" &&
          "results" in response.data
        ) {
          if (response.data["results"].length === 0) {
            setMeetPalState("noMeetings");
          } else {
            setBackendResponse(response.data["results"]);
            setMeetPalState("loaded");
          }
        } else if (
          response.data["status"] === "ERROR" &&
          (response.data["error"] === "PERMISSION_DENIED" ||
            response.data["error"] === "UNAUTHENTICATED")
        ) {
          setMeetPalState("permission_denied");
        } else {
          setMeetPalState("error");
        }
      })
      .catch((error) => {
        setMeetPalState("error");
      })
      .finally(() => {
        setReloading(false);
      });
  }

  useEffect(() => {
    if (!currentDate) {
      return;
    }
    console.log("Run useEffect with: ", currentDate);

    setChosen(null);
    setVars(defaultVars);

    if (session) {
      getMeetings();

      // Re-set interval
      if (currentInterval) {
        removeAutoRefresh();
      }
      const interval = setInterval(() => getMeetings(true), refreshInterval);
      setCurrentInterval(interval);
    }
  }, [session, currentDate]);

  useLayoutEffect(() => {
    // Scroll to current meeting
    if (!(!reloading && backendResponse && meetPalState === "loaded")) return;

    setTimeout(() => {
      const element = currentMeetingRef.current;
      element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  }, [backendResponse, reloading, meetPalState]);

  function toggleMeeting(id) {
    let tmp = { ...toggles };
    tmp[id] = !tmp[id];
    setToggles(tmp);
    Object.assign(tmp, { index: !toggles[id] });
  }

  function updatePages(id) {
    setPaginationLoading(true);
    setTimeout(function () {
      let tmp = { ...pages };
      tmp[id] = tmp.hasOwnProperty(id) ? tmp[id] + 1 : 2;
      setPages(tmp);
      setPaginationLoading(false);
    }, 600);
  }

  function handleReload() {
    setChosen(null);
    setVars(defaultVars);
    setReloading(true);
    setMeetPalState("loading");
    getMeetings();

    if (!currentInterval) {
      const interval = setInterval(() => getMeetings(true), refreshInterval);
      setCurrentInterval(interval);
    }
  }

  function handleRelogin() {
    setRelogin(true);
    setTimeout(() => {
      setRelogin(false);
    }, 4000);

    window.open("http://app.meetpal.co/login?desktop=1");
  }

  function handleChosenCompany(companyName, participant) {
    let companyDomain = participant.email.split("@")[1];
    setChosen(companyName);
    setVars(ChosenVars);

    getMeetingsWithSomeone(companyDomain);
  }

  function handleChosen(participant) {
    setChosen(participant.full_name);
    setVars(ChosenVars);

    getMeetingsWithSomeone(participant.email);
  }

  function setDefaultToggles(meetings) {
    var t = {};
    for (var meeting in meetings) {
      if (meetings[meeting]["should_be_open"]) {
        t[meetings[meeting]["id"]] = true;
        break;
      }
    }

    setToggles(t);
  }

  function relativeTime(start_time, end_time) {
    var now = new Date().getTime();
    var realtiveTo, isCurrentMeeting;
    start_time = Date.parse(start_time);
    end_time = Date.parse(end_time);

    if (now < start_time) {
      realtiveTo = start_time;
    } else if (now > end_time) {
      realtiveTo = end_time;
    } else {
      isCurrentMeeting = true;
    }

    if (isCurrentMeeting) {
      return "Current";
    } else {
      var start_time_d = new Date(start_time);
      var end_time_d = new Date(end_time);

      return (
        <Tooltip
          title={`${formatAMPM(start_time_d)} - ${formatAMPM(end_time_d)}`}
          disableInteractive
          placement="top"
        >
          <div style={{ display: "inherit" }}>
            <ReactTimeAgo date={realtiveTo} timeStyle="round-minute" tooltip={false} />
          </div>
        </Tooltip>
      );
    }
  }

  function handleMeetingMenuClick(event) {
    setAnchorEl(event.currentTarget);
    setOpenMenu(event.target.parentElement.id);
  }
  function handleMeetingMenuClose(event) {
    setAnchorEl(null);
    setOpenMenu(null);
  }

  const BackButton = () => {
    return (
      <LoadingButton
        size="small"
        onClick={handleReload}
        loading={reloading}
        // @ts-ignore
        variant=""
        sx={{ p: "0px", minWidth: "30px" }}
      >
        <ArrowBack fontSize="small" />{" "}
      </LoadingButton>
    );
  };

  const getPeriodFromDate = (dateStr) => {
    let curent = parseDateStrToDate(dateStr);
    let dayBefore = new Date();
    let dayAfter = new Date();
    dayAfter.setDate(curent.getDate() + 1);
    dayBefore.setDate(curent.getDate() - 1);

    if (curent.toDateString() === new Date().toDateString()) {
      return "today";
    } else if (dayBefore.toDateString() === new Date().toDateString()) {
      return "tomorrow";
    } else if (dayAfter.toDateString() === new Date().toDateString()) {
      return "yesterday";
    }

    return dateStr;
  };

  const backToToday = () => {
    setMeetPalState("loading");
    setCurrentDate(parseDateToDateStr(new Date()));
  };

  const dateBeforeClicked = () => {
    let curent = parseDateStrToDate(currentDate);
    let dayBefore = new Date();
    dayBefore.setDate(curent.getDate() - 1);

    setMeetPalState("loading");
    setCurrentDate(parseDateToDateStr(dayBefore));
  };

  const dateAfterClicked = () => {
    console.log("currentDate: ", currentDate);
    let curent = parseDateStrToDate(currentDate);
    let dayAfter = new Date();
    dayAfter.setDate(curent.getDate() + 1);
    console.log("dayAfter: ", parseDateToDateStr(dayAfter));

    setMeetPalState("loading");
    setCurrentDate(parseDateToDateStr(dayAfter));
  };

  const parseDateToDateStr = (dateObj) => {
    // get: Thu Feb 09 2023 19:43:27 GMT+0200 (Israel Standard Time)
    // return: '2023-02-09'
    var tzoffset = dateObj.getTimezoneOffset() * 60000; //offset in milliseconds
    var localISOTime = new Date(dateObj - tzoffset).toISOString().slice(0, -1);

    return localISOTime.slice(0, 10);
  };

  const parseDateStrToDate = (dateStr) => {
    // get '2023-02-09'
    // return: Date()
    let splits = dateStr.split("-");
    let year = parseInt(splits[0]);
    let month = parseInt(splits[1]) - 1;
    let day = parseInt(splits[2]);
    return new Date(year, month, day, 0, 0, 0, 0);
  };

  const parseDateStringToDate = (dateStr) => {
    // get '2023-02-09'
    if (!dateStr) {
      return;
    }

    console.log(
      "currentDate: ",
      currentDate,
      " dateStr: ",
      dateStr,
      "  parseDateStrToDate(dateStr).toDateString(): ",
      parseDateStrToDate(dateStr).toDateString()
    );

    let curent = parseDateStrToDate(dateStr);
    let dayBefore = new Date();
    let dayAfter = new Date();
    dayAfter.setDate(curent.getDate() + 1);
    dayBefore.setDate(curent.getDate() - 1);

    if (curent.toDateString() === new Date().toDateString()) {
      return "Today";
    } else if (dayBefore.toDateString() === new Date().toDateString()) {
      return "Tomorrow";
    } else if (dayAfter.toDateString() === new Date().toDateString()) {
      return "Yesterday";
    }

    let splits = dateStr.split("-");
    let year = parseInt(splits[0]);
    let month = parseInt(splits[1]) - 1;
    let day = parseInt(splits[2]);

    console.log(`${year} ${month} ${day}`);
    let date = new Date(year, month, day, 0, 0, 0, 0).toDateString();
    console.log(`date ${date}`);
    let dayOfTheWeek = date.split(" ")[0];
    let monthStr = date.split(" ")[2];
    let dayStr = date.split(" ")[1];

    return `${dayOfTheWeek}, ${dayStr} ${monthStr}`;
  };

  const ParticipantsHeader = () => {
    return (
      <Box
        sx={{
          "-webkit-app-region": "drag",
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 10,
        }}
      >
        <TableContainer
          sx={{
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Table
            sx={{ maxWidth: "360px", [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }}
            size="medium"
          >
            <TableHead sx={{ p: "0px", maxWidth: "360px" }}>
              <TableCell
                colSpan={1}
                sx={{
                  ...(!chosen && {
                    pl: "15px",
                  }),
                  // @ts-ignore
                  ...(chosen && {
                    pl: "7px",
                  }),
                  fontWeight: "500",
                  pl: "10px",
                  fontSize: "17px",
                  "& .MuiSelect-standard": { paddingRight: "0 !important", pb: 0 },
                  paddingBottom: 0,
                }}
              >
                {chosen ? (
                  <Table>
                    <TableHead sx={{ p: "0px" }}>
                      <TableCell sx={{ p: "0px" }} onClick={dateBeforeClicked} width={1}>
                        <IconButton sx={{ p: "0px" }} aria-label="row" size="small">
                          <ArrowBack fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ p: "0px" }}>
                        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                          <strong> Meetings with: {chosen} </strong>
                        </Typography>
                      </TableCell>
                    </TableHead>
                  </Table>
                ) : (
                  <Table>
                    <TableHead sx={{ p: "0px" }}>
                      <TableCell sx={{ p: "0px" }} onClick={dateBeforeClicked} width={1}>
                        <IconButton sx={{ p: "0px" }} aria-label="row" size="small">
                          <ArrowBack fontSize="small" />
                        </IconButton>
                      </TableCell>

                      <TableCell sx={{ p: "0px" }} width={"100px"}>
                        {currentDate && (
                          <Typography sx={{ textAlign: "center", fontWeight: 600 }}>
                            {parseDateStringToDate(currentDate)}{" "}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell
                        sx={{ p: "0px" }}
                        // @ts-ignore
                        maxWidth={"40px"}
                        onClick={dateAfterClicked}
                        width={1}
                      >
                        <IconButton sx={{ p: "0px" }} aria-label="row" size="small">
                          <ArrowForward fontSize="small" />
                        </IconButton>
                      </TableCell>

                      <TableCell
                        sx={{ p: "0px", pl: "0px" }}
                        // @ts-ignore
                        maxWidth={"40px"}
                      >
                        {parseDateStringToDate(currentDate) != "Today" && (
                          <LoadingButton
                            onClick={() => backToToday()}
                            loading={reloading}
                            sx={{
                              size: "small",
                              color: "gray",
                              minWidth: "45px",
                              textTransform: "capitalize",
                              p: "0px",
                            }}
                            variant="text"
                            color="primary"
                          >
                            {" "}
                            Today
                          </LoadingButton>
                        )}
                      </TableCell>
                    </TableHead>
                  </Table>
                )}
              </TableCell>

              <TableCell colSpan={5} sx={{ pr: "5px", textAlign: "right", paddingBottom: 0 }}>
                <ReloadButton reloading={reloading} handleReload={handleReload} />
              </TableCell>
            </TableHead>
          </Table>
        </TableContainer>
        <Box sx={{ paddingBottom: "5px", mx: "10px" }}>
          <hr style={{ marginTop: "3px", marginBottom: "0px" }} />
        </Box>
      </Box>
    );
  };

  if (meetPalState === "error") {
    return <UnknownError reloading={reloading} handleReload={handleReload} />;
  } else if (meetPalState === "noMeetings") {
    return (
      <>
        <ParticipantsHeader />
        {reloading || meetPalState === "loading" ? (
          <SpinnerComponent />
        ) : (
          <NoMeetings period={getPeriodFromDate(currentDate)} />
        )}
      </>
    );
  } else if (meetPalState === "permission_denied") {
    return <Login relogin={relogin} handleRelogin={handleRelogin} />;
  }

  return (
    <>
      <ParticipantsHeader />
      {reloading || meetPalState === "loading" ? (
        <SpinnerComponent />
      ) : meetPalState === "ERR_NETWORK" ? (
        <NotConnected handleReload={handleReload} reloading={reloading} />
      ) : (
        <TableContainer sx={{ display: "flex", justifyContent: "center", pt: "5px" }}>
          <Table
            sx={{ tableLayout: "fixed", [`& .${tableCellClasses.root}`]: { borderBottom: "none" } }}
            size="small"
          >
            <TableBody>
              {backendResponse.map((meeting, meetingIndex) => {
                return (
                  <>
                    <TableRow
                      ref={meeting["should_be_open"] ? currentMeetingRef : null}
                      // @ts-ignore
                      style={vars}
                      key={meetingIndex}
                      hover
                      selected={toggles[meeting["id"]]}
                      // sx={{ height: (toggles[meeting["id"]] ? "50px" : "37px"  ) }}
                      sx={{
                        width: "90%",
                        height: "50px",
                        px: "5px",
                        [`&.Mui-selected`]: {
                          backgroundColor: "var(--color)",
                        },
                        [`&.Mui-selected:hover`]: {
                          backgroundColor: "var(--hover-color)",
                        },
                      }}
                    >
                      <TableCell
                        colSpan={4}
                        sx={{
                          ...companyStyle,
                          cursor: "pointer",
                          py: 0,
                          width: "90%",
                        }}
                        onClick={() => toggleMeeting(meeting["id"])}
                      >
                        <IconButton aria-label="expand row" size="small">
                          {!toggles[meeting["id"]] ? (
                            <KeyboardArrowRightIcon
                              sx={{ transition: "transform 0.15s ease-in-out" }}
                            />
                          ) : (
                            <KeyboardArrowRightIcon
                              sx={{
                                transform: "rotate(90deg)",
                                transition: "transform 0.15s ease-in-out",
                              }}
                            />
                          )}
                        </IconButton>
                        <div style={{ display: "inline-flex" }}>
                          {" "}
                          <div
                            style={{
                              fontWeight: 400,
                              maxWidth: "199px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {meeting["summary"] || "(No title)"}
                          </div>{" "}
                        </div>{" "}
                        -{" "}
                        <Typography sx={{ fontSize: "0.75rem", color: "#474747" }} component="span">
                          {relativeTime(meeting["start_time"], meeting["end_time"])}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ ...companyStyle, cursor: "pointer", width: "36px" }}>
                        <IconButton
                          aria-label="more"
                          id={meeting["id"]}
                          aria-controls={
                            openMenu === meeting["id"] ? "long-menu" + meeting["id"] : undefined
                          }
                          aria-expanded={openMenu === meeting["id"] ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleMeetingMenuClick}
                        >
                          <MoreVertIcon
                            fontSize="small"
                            // sx={{ marginRight: "-5px", }}
                            // onClick={() =>
                            //   window.open(
                            //     `https://calendar.google.com/calendar/u/${email}?eid=${meeting["eid"]}`
                            //   )
                            // }
                          />
                        </IconButton>
                        <Menu
                          id={"long-menu" + meeting["id"]}
                          MenuListProps={{
                            "aria-labelledby": "long-button" + meeting["id"],
                          }}
                          anchorEl={anchorEl}
                          open={openMenu === meeting["id"]}
                          onClose={handleMeetingMenuClose}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                          <MenuItem
                            key="open-event"
                            onClick={() => {
                              window.open(
                                `https://calendar.google.com/calendar/u/${email}?eid=${meeting["eid"]}`
                              );
                              handleMeetingMenuClose();
                            }}
                          >
                            <ListItemIcon>
                              <IconEvent fontSize="small" />
                            </ListItemIcon>
                            <Typography variant="inherit">Open event</Typography>
                          </MenuItem>
                          {meeting["video_link"] && (
                            <MenuItem
                              key="copy-link"
                              onClick={() => {
                                // window.electronAPI.copyToClipboard(meeting["video_link"]);
                                handleMeetingMenuClose();
                              }}
                            >
                              <ListItemIcon>
                                <IconLink fontSize="small" />
                              </ListItemIcon>
                              <Typography variant="inherit">Copy meeting link</Typography>
                            </MenuItem>
                          )}
                          {meeting["video_client"] && (
                            <MenuItem
                              key="start-meeting"
                              onClick={() => {
                                window.open(meeting["video_link"]);
                                handleMeetingMenuClose();
                              }}
                            >
                              <ListItemIcon>
                                <IconPlayCircleFilled fontSize="small" />
                              </ListItemIcon>
                              <Typography variant="inherit">
                                Join {meeting["video_client"]}
                              </Typography>
                            </MenuItem>
                          )}
                        </Menu>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ py: 0, px: "5px" }} colSpan={6}>
                        <Collapse in={toggles[meeting["id"]]} timeout="auto" unmountOnExit>
                          {meeting["attendees"].map((participant, attendeeIndex) => {
                            var currentPage = PAGE_SIZE * (pages[meeting["id"]] || 1);
                            if (attendeeIndex === currentPage) {
                              return (
                                <center style={{ marginBottom: "8px" }}>
                                  <LoadingButton
                                    variant="contained"
                                    onClick={() => updatePages(meeting["id"])}
                                    size="small"
                                    loading={paginationLoading}
                                    sx={{
                                      textTransform: "none",
                                      borderRadius: "15px",
                                      backgroundColor: "#F1F3F4",
                                      boxShadow: "none",
                                      color: "#202124",
                                      py: "2px",
                                      px: "15px",
                                      cursor: "pointer",
                                      ":hover": {
                                        backgroundColor: "#D8D7DC",
                                        boxShadow: "none",
                                      },
                                    }}
                                  >
                                    More results
                                  </LoadingButton>
                                  <br />
                                </center>
                              );
                            } else if (attendeeIndex > currentPage - 1) {
                              return "";
                            }

                            return (
                              <TableRow
                                key={`${meetingIndex}-${attendeeIndex}`}
                                sx={{ width: "inherit" }}
                              >
                                <TableCell
                                  sx={{ ...participantStyle, width: "40px", cursor: "pointer" }}
                                  onClick={() =>
                                    handleChosenCompany(participant.company_name, participant)
                                  }
                                >
                                  <Tooltip
                                    title={participant.company_name || "N/A"}
                                    disableInteractive
                                  >
                                    <Avatar
                                      src={participant.company_logo}
                                      {...stringAvatar(
                                        participant.company_name,
                                        participant.company_logo
                                      )}
                                    />
                                  </Tooltip>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    ...participantStyle,
                                    minWidth: "120px",
                                    maxWidth: "120px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleChosen(participant)}
                                >
                                  <Tooltip title={participant.email} disableInteractive>
                                    <span>{participant.full_name}</span>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={{ ...participantStyle, width: "140px" }}>
                                  {participant.title}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    ...participantStyle,
                                    width: "36px",
                                    alignItems: "right",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "right",
                                    }}
                                  >
                                    {linkedinLink(
                                      participant.linkedin_url,
                                      participant.full_name,
                                      participant.company_name
                                    )}
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {meeting["attendees"].length === 0 ? <NoMeetingAttendees /> : ""}
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default memo(Participantsview);
