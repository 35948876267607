import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Link, Typography } from "@mui/material";
import OnBoardingTitle from "./on-boarding-title.component";

export default function OnBoardingContainer({ children }) {
  var vw = 65;

  return (
    <Grid
      item
      xs={12}
      sm={8}
      md={7}
      component={Paper}
      elevation={6}
      square
      sx={{ boxShadow: "none", justifyContent: "center", display: "flex" }}
    >
      <Box
        sx={{
          py: 10,
          mx: 4,
          display: "flex",
          flexDirection: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            "@media screen and (max-width: 600px)": {
              px: 7,
              mx: 4,
            },
            width: "450px",
          }}
        >
          <Box sx={{ mb: 4 }}>
            <OnBoardingTitle>Welcome to</OnBoardingTitle>
            <Box
              sx={{
                display: "inline-flex",
              }}
            >
              <img src="/meetpal-logo.png" width={160} alt="MeetPal" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {children}

            <Box component="form" noValidate sx={{ mt: 1, alignSelf: "center" }}>
              <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 5 }}>
                {"By continuing I agree to the "}
                <Link color="inherit" href="https://www.meetpal.co/privacy-policy">
                  Privacy Policy
                </Link>{" "}
                {"and "}
                <Link color="inherit" href="https://www.meetpal.co/terms-of-use">
                  Terms of Use
                </Link>{" "}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
//
