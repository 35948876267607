import {
  setCookieForUserAuthentication,
  eraseCookieForUserAuthentication,
  getCookieForUserAuthentication,
} from "./cookies";

import { axiosMethods as axios } from "./axiosHelper";

const API_PATH = "https://dev.meetpal.co/api/v1";
// const API_PATH = "http://localhost:5004/api/v1";

function buildUrl(path) {
  return API_PATH + path;
}

function formatParmas(params) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}

function buildUrlWithParams(path, params) {
  return `${buildUrl(path)}?${formatParmas(params)}`;
}

export async function setTokenForSession() {
  const session = getCookieForUserAuthentication();
  console.log("session: ", session);

  if (session) {
    const resp = await axios.get(
      buildUrlWithParams("/set_token_for_session", { session: session })
    );

    if (!resp) {
      return {};
    }

    const { status } = resp.data;

    if (status === "OK") {
      return resp.data.one_time_token;
    }
  } else {
    if (window.location.pathname != "/login") {
      window.location = `${window.location.origin}/login?login-error=1`;
    }
    // console.log("NO SESSION");
  }

  return false;
}

export async function getProfile() {
  const session = getCookieForUserAuthentication();

  if (session) {
    const resp = await axios.get(buildUrl("/profile"));

    if (!resp) {
      return {};
    }

    const { status } = resp.data;

    if (status === "OK") {
      return resp.data;
    }
  } else {
    if (window.location.pathname != "/login") {
      window.location = `${window.location.origin}/login?login-error=1`;
    }
    // console.log("NO SESSION");
  }

  return {};
}

export async function getLatestReleases() {
  const resp = await axios.get(buildUrl("/get_latest_versions"));

  if (!resp) {
    return {};
  }

  const { mac_url, windows_url } = resp.data;

  return { macUrl: mac_url, windowsUrl: windows_url };
}

export async function updateAppStatus(appType, status) {
  const session = getCookieForUserAuthentication();
  // [WINDOWS / MAC]
  const params = { app_type: appType, status: status };

  if (session) {
    const resp = await axios.post(buildUrl("/update_desktop_app"), params);

    if (!resp) {
      return {};
    }

    const { status } = resp.data;

    if (status === "OK") {
      return resp.data;
    }
  } else {
    if (window.location.pathname != "/login") {
      window.location = `${window.location.origin}/login?login-error=1`;
    }
    // console.log("NO SESSION");
  }

  return {};
}

export async function userLogin(user, token) {
  await user
    .getIdToken()
    .then(async function (idToken) {
      const params = { firbase_token: idToken, google_token: token };

      const resp = await axios.post(buildUrl("/google_redirect"), params);
      const { status } = resp.data;

      if (status === "OK") {
        const { session } = resp.data;

        setCookieForUserAuthentication(session);
      } else {
        eraseCookieForUserAuthentication();
      }
    })
    .catch((e) => {
      return `Something went wrong, Please contact us.${e}`;
    });
}
