import React from "react";
import Typography from "@mui/material/Typography";

export default function OnBoardingText(props) {
  return (
    <Typography
      variant="subtitle1"
      color={"rgb(97, 97, 97)"}
      fontWeight={400}
      mb={props.mb || 5}
    >
      {props.children}
    </Typography>
  );
}
