// @ts-nocheck
/* globals FS */
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  eraseCookieForUserAuthentication,
  getCookieForUserAuthentication,
  setCookieForUserAuthentication,
  eraseCookieForElectron,
  getCookieForElectron,
  setCookieForElectron,
} from "../global/cookies";
import { useSearchParams } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/material";
import { getProfile, updateAppStatus } from "global/api";
import { ClipLoader } from "react-spinners";
import OnBoardingButton from "components/home-download-button.component";
import ResponsiveAppBar from "components/navbar.component";

const REMOVE_ZOOM_PATH = "https://marketplace.zoom.us/user/installed";
const INSTALL_ZOOM_PATH = "https://zoom.meetpal.co/api/zoomapp/install";

export default function AuthValidate(props) {
  let [connectionSuccesss, setConnectionSuccesss] = useState(false);
  let [signoutLoading, setSignoutLoading] = useState(false);

  let [user, setUser] = useState(null);
  let [isLoad, setIsLoad] = useState(true);
  let [installLoading, setInstallLoading] = useState(false);
  let [denyLoading, setDenyLoading] = useState(false);

  const [searchParams] = useSearchParams();

  if (searchParams.get("session")) {
    setCookieForUserAuthentication(searchParams.get("session"));
  }

  // client install id
  if (searchParams.get("id")) {
    setCookieForElectron();
  }

  const handleDeepLinkClick = () => {
    setInstallLoading(!installLoading);
    eraseCookieForElectron();

    var myInterval = setInterval(async () => {
      if (installLoading) {
        setInstallLoading((installLoading) => !installLoading);
      } else {
        setInstallLoading(false);
        clearInterval(myInterval);
        setConnectionSuccesss(true);

        await updateAppStatus("need_to_check", "INSTALLED");
        window.location.href = `meetpal://login?session=${getCookieForUserAuthentication()}`;
      }
    }, 1500);
  };

  const handleNotMe = () => {
    // @ts-ignore
    setDenyLoading(false);
    window.location.href = `/auth/validate/deny`;
  };

  const redirectToLoginWithError = () => {
    eraseCookieForUserAuthentication();
    // @ts-ignore
    window.location.href = `/login?login-error=1`;
  };

  const redirectToGoogleLogin = () => {
    // @ts-ignore
    window.location.href = `/login`;
  };

  const redirectToAcceptCalendar = () => {
    // @ts-ignore
    // window.location.href = `/pre-calendar?session=${getCookieForUserAuthentication()}`;
  };

  const fetchData = async () => {
    const data = await getProfile()
      .then((res) => {
        setIsLoad(true);
        // console.log("PROFILE RESP: ", res);
        setUser(res);
        let isDeepLink = getCookieForElectron();

        console.log("res: ", isDeepLink);

        if (res.status === "OK") {
          if (res.need_to_relogin) {
            redirectToLoginWithError();
          } else if ((res.cal_accepted || res.connected_with_microsoft) && isDeepLink) {
            // OPEN ELECTRON
            setIsLoad(false);
          } else if (!res.cal_accepted && !res.connected_with_microsoft) {
            redirectToAcceptCalendar();
          } else if (!res.zoom_installed) {
            setIsLoad(false);
          } else {
            setIsLoad(false);
          }
        } else {
          // guest
          console.log("1");
          redirectToGoogleLogin();
        }
      })
      .finally(() => {
        // console.log("done api");
        // setIsLoad(false);
      });
  };

  useEffect(() => {
    fetchData();
    console.log("connectionSuccesss: ", connectionSuccesss);
  }, []);

  return isLoad ? (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      component="main"
      sx={{ height: "100vh" }}
    >
      <ClipLoader color={"#36d7b7"} loading={isLoad} />
    </Grid>
  ) : (
    <>
      <ResponsiveAppBar
        user={user}
        photo_url={user.photo_url}
        signoutLoading={signoutLoading}
        setSignoutLoading={setSignoutLoading}
      />
      {connectionSuccesss ? (
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          component="main"
          spacing={1}
          sx={{ pt: "150px" }}
        >
          <Grid item>
            <CheckCircleOutlineIcon fontSize="large" color="green" />
          </Grid>
          <Grid item>
            <Typography textAlign={"center"} variant="h5" fontWeight={800}>
              Congratulations!
            </Typography>
          </Grid>
          <Grid item></Grid>
          <Grid item>
            <Typography textAlign={"center"} variant="subtitle1" fontWeight={400}>
              You aisLoadre now successfully authenticated.
            </Typography>
            <Typography textAlign={"center"} variant="subtitle1" fontWeight={400}>
              You can now close this tab, or visit{" "}
              <a href="/home" style={{ color: "#0071bc", textDecoration: "none" }}>
                your dashboard.
              </a>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          <Box maxWidth={"45%"} sx={{ margin: "auto", pt: "20px" }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={4}
              sx={{ margin: "auto" }}
            >
              <Grid item>
                <Box
                  width={150}
                  height={175}
                  sx={{
                    borderRadius: "5px",
                    margin: "auto",
                    backgroundImage: "url(/login-from-desktop.png)",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: (t) =>
                      t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Grid>

              <Grid item>
                <Typography textAlign={"center"} variant="h4" fontWeight={800}>
                  You requested authentication for a new device
                </Typography>
              </Grid>
              <Grid item></Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <OnBoardingButton
                    handleClick={handleDeepLinkClick}
                    width={200}
                    height={200}
                    showIcon={false}
                    iconPath={"meetpal-pic.png"}
                    loading={installLoading}
                    borderRadius={"20px"}
                    sx={{
                      backgroundRepeat: "no-repeat",
                      backgroundColor: (t) =>
                        t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    Yes, this is me!
                  </OnBoardingButton>
                </Grid>

                <Grid item>
                  <OnBoardingButton
                    handleClick={handleNotMe}
                    color={"info"}
                    width={200}
                    height={200}
                    showIcon={false}
                    iconPath={"meetpal-pic.png"}
                    loading={denyLoading}
                    borderRadius={"20px"}
                    sx={{
                      margin: "auto",
                      backgroundRepeat: "no-repeat",
                      backgroundColor: (t) =>
                        t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    No, it's not me!
                  </OnBoardingButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
