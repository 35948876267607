import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "pages/home.component";
import GoogleOnBoarding from "pages/step1-google-on-boarding.component";
import CalendarOnBoarding from "pages/step2-calendar-on-boarding.component";
import ZoomOnBoarding from "pages/step3-zoom-on-boarding.component";
import AuthValidate from "pages/auth-validate.component";
import AuthValidateDeny from "pages/auth-validate-deny.component";

const routes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/home" element={<Home />} />

    <Route path="auth/validate" element={<AuthValidate />} />
    <Route path="auth/validate/deny" element={<AuthValidateDeny />} />

    <Route path="login" element={<GoogleOnBoarding />} />
    <Route path="pre-calendar" element={<CalendarOnBoarding />} />
    {/* <Route path="pre-zoom" element={<ZoomOnBoarding />} /> */}
  </Routes>
);

export default routes;
