import axios from "axios";
import { getCookieForUserAuthentication, eraseCookieForUserAuthentication } from "./cookies";

// TODO: pass params as object
export function apiCaller(type = "get", path, params, headers, uploadProgress, timeout) {
  return new Promise((resolve, reject) => {
    axios
      .request({
        url: path,
        method: type,
        headers: {
          MEETPAL_SESSION: `${getCookieForUserAuthentication()}`,
          ...headers,
        },
        data: params,
        timeout: timeout || 60000,
        onUploadProgress: uploadProgress || (() => {}),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
        if (error.response && error.response.status === 401) {
          eraseCookieForUserAuthentication();
          window.location = `${window.location.origin}/login?login-error=1`;
        } else {
          reject(error);
        }
      });
  });
}

export const axiosMethods = {
  ...axios,
  get: function (...args) {
    return apiCaller("get", ...args);
  },
  put: function (...args) {
    return apiCaller("put", ...args);
  },
  post: function (...args) {
    return apiCaller("post", ...args);
  },
  patch: function (...args) {
    return apiCaller("patch", ...args);
  },
  delete: function (...args) {
    return apiCaller("delete", ...args);
  },
  head: function (...args) {
    return apiCaller("head", ...args);
  },
};
