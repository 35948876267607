import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ReloadButton } from "./ReloadButton";

export const UnknownError = ({ reloading, handleReload }) => {
  return (
    <Box sx={{ m: "4px" }}>
      {reloading && (
        <Box sx={{ textAlign: "right" }}>
          <ReloadButton reloading={reloading} handleReload={handleReload} />
        </Box>
      )}
      <Typography sx={{ padding: "10px" }}>
        Sorry, we were unable to fetch your calendar events at this time. <br /> Please try again
        later. If you need further assistance, please contact our support team at{" "}
        <Link href="#" onClick={() => window.open("mailto:support@meetpal.co")}>
          support@meetpal.co
        </Link>
      </Typography>
    </Box>
  );
};
