// @ts-nocheck
/* globals FS */
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Typography from "@mui/material/Typography";
import OnBoardingTitle from "./on-boarding-title.component";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import OnBoardingButton from "./home-download-button.component";
import { updateAppStatus } from "global/api";

export default function DownloadMobile({ user, children }) {
  let [installMacLoading, setInstallMacLoading] = useState(false);
  let [installWindowsLoading, setInstallWindowsLoading] = useState(false);
  let [exampleView, setExampleView] = useState(false);

  const participantStyle = {
    padding: "6px",
  };

  useEffect(() => {}, [user]);

  const handleMacClicked = async () => {
    setInstallMacLoading(!installMacLoading);
    await updateAppStatus("MAC", "DOWNLOADED");

    var myInterval = setInterval(async () => {
      console.log("interval: ", myInterval, "installMacLoading: ", installMacLoading);

      if (installMacLoading) {
        setInstallMacLoading((installMacLoading) => !installMacLoading);
      } else {
        setInstallMacLoading(false);
        clearInterval(myInterval);

        if (!isMobile) {
          if (user.mac_installed) {
            // window.open(`meetpal://openApp`);

            // Send event that download triggered
            FS.event("Mac Open Clicked", { user: user });
          } else {
            // Send event that download triggered
            FS.event("Mac Download Clicked", { user: user });
          }
        }

        if (isMobile) {
          let alertMsg = "For the download, please use a desktop computer.";
          return alert(alertMsg);
        }
      }
    }, 1500);
  };

  const handleWindowsClicked = async () => {
    setInstallWindowsLoading(!installWindowsLoading);
    await updateAppStatus("WINDOWS", "DOWNLOADED");

    var myInterval = setInterval(async () => {
      console.log("interval: ", myInterval, "installWindowsLoading: ", installWindowsLoading);

      if (installWindowsLoading) {
        setInstallWindowsLoading((installWindowsLoading) => !installWindowsLoading);
      } else {
        setInstallWindowsLoading(false);
        clearInterval(myInterval);

        if (!isMobile) {
          if (user.windows_installed) {
            // window.open(`meetpal://openApp`);

            // Send event that download triggered
            FS.event("Windows Open Clicked", { user: user });
          } else {
            // Send event that download triggered
            FS.event("Windows Download Clicked", { user: user });
          }
        }

        if (isMobile) {
          let alertMsg = "For the download, please use a desktop computer.";
          return alert(alertMsg);
        }
      }
    }, 1500);
  };

  return (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      px={"10px"}
      mb={"1em"}
      marginTop={"20px"}
      component="main"
    >
      <OnBoardingTitle>Hey {user.display_name || user.email}, Welcome! </OnBoardingTitle>

      {!exampleView && <>{children}</>}
      {exampleView && (
        <>
          <Typography fontSize={22} fontWeight={400} gutterBottom>
            Select the platform that suits you best.
          </Typography>

          <TableContainer sx={{ display: "flex", justifyContent: "center" }}>
            <Table
              sx={{
                borderSpacing: "0px 0px",
                borderCollapse: "separate",
                maxWidth: "800px",
                [`& .${tableCellClasses.root}`]: { borderBottom: "none" },
              }}
              size="small"
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...participantStyle, pb: "25px" }}>
                    <Box
                      width={150}
                      height={150}
                      sx={{
                        borderRadius: "5px",
                        margin: "auto",
                        backgroundImage: "url(/apple-logo.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                  </TableCell>

                  <TableCell sx={{ ...participantStyle, pb: "25px" }}>
                    <Box
                      width={150}
                      height={150}
                      sx={{
                        borderRadius: "5px",
                        margin: "auto",
                        backgroundImage: "url(/windows-logo.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ ...participantStyle, textAlign: "center" }}>
                    <OnBoardingButton
                      handleClick={handleMacClicked}
                      width={150}
                      height={150}
                      iconPath={"download.svg"}
                      showIcon={true}
                      loading={installMacLoading}
                      sx={{
                        borderRadius: "5px",
                        margin: "auto",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      {"Mac"}
                    </OnBoardingButton>
                  </TableCell>
                  <TableCell sx={{ ...participantStyle, textAlign: "center" }}>
                    <OnBoardingButton
                      handleClick={handleWindowsClicked}
                      width={150}
                      height={150}
                      iconPath={!user.windows_installed ? "download.svg" : ""}
                      showIcon={!user.windows_installed}
                      loading={installWindowsLoading}
                      sx={{
                        borderRadius: "5px",
                        margin: "auto",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      {user.windows_installed ? "Open App" : "Windows"}
                    </OnBoardingButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ ...participantStyle }}>
                    {user.windows_installed && (
                      <Box sx={{ maxWidth: "150px", margin: "auto" }}>
                        ✅ Already installed, click to open the app
                      </Box>
                    )}
                  </TableCell>
                  <TableCell sx={{ ...participantStyle }}>
                    {user.linux_installed && (
                      <Box sx={{ maxWidth: "150px", margin: "auto" }}>
                        ✅ Already installed, click to open the app
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Grid>
  );
}
