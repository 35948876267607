import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export default function OnBoardingImage({ imagePath, isCal = false }) {
  let mb = 1,
    scale = 1.59,
    width = isCal ? 20 * scale : 20,
    height = isCal ? 20 : width * scale;
  return (
    <Grid
      item
      container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      xs={false}
      sm={4}
      md={5}
      sx={{
        display: { xs: "none", sm: "flex" },
        // backgroundColor: "#14afd029",
      }}
    >
      <Box
        sx={{
          backgroundColor: "red",
          display: "flex",
          margin: "25px",
          width: "-webkit-fill-available",
          height: "100%",
          backgroundColor: "#14afd029",
        }}
      >
        <Box
          width={`${width}vw`}
          height={`${height}vw`}
          sx={{
            borderRadius: "5px",
            margin: "auto",
            backgroundImage: `url(${imagePath})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Box>
    </Grid>
  );
}
