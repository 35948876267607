const cookies = require("browser-cookies");

function setCookie(cookieName, value) {
  return cookies.set(cookieName, value);
}

function eraseCookie(cookieName) {
  return cookies.erase(cookieName);
}

function getCookie(cookieName) {
  return cookies.get(cookieName);
}

function setCookieForUserAuthentication(token) {
  cookies.set("_meetpal_session", token);
  cookies.set("_meetpal_session", token, { expires: 90, domain: "meetpal.co" });
}

function getCookieForUserAuthentication() {
  return cookies.get("_meetpal_session");
}

function eraseCookieForUserAuthentication() {
  cookies.erase("_meetpal_session");
  cookies.erase("_meetpal_session", { domain: "meetpal.co" });
}

function setCookieForElectron() {
  cookies.set("_meetpal_electron", "ok");
  cookies.set("_meetpal_electron", "ok", { expires: 90, domain: "meetpal.co" });
}

function getCookieForElectron() {
  return cookies.get("_meetpal_electron");
}

function eraseCookieForElectron() {
  cookies.erase("_meetpal_electron");
  cookies.erase("_meetpal_electron", { domain: "meetpal.co" });
}

module.exports = {
  setCookieForUserAuthentication,
  getCookieForUserAuthentication,
  eraseCookieForUserAuthentication,
  setCookieForElectron,
  getCookieForElectron,
  eraseCookieForElectron,
  setCookie,
  getCookie,
  eraseCookie,
};
