// @ts-nocheck
import React from "react";
import { Avatar, Button } from "@mui/material";
import { ClipLoader } from "react-spinners";

export default function HomeDownloadButton({
  color = "primary",
  variant = "contained",
  width = "100%",
  py = "8px",
  linkUrl = null,

  borderRadius = "0px",
  mb = 0,
  loading,
  handleClick,
  showIcon = true,
  iconPath = "/google-icon.png",
  children,
}) {
  return (
    <Button
      size="large"
      variant={variant}
      href={linkUrl}
      // @ts-ignore
      color={color}
      sx={{
        py: py,
        fontFamily: `Poppins, Gotham, "Proxima Nova", "Avenir Next" , system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, sans-serif;`,
        marginBottom: mb,
        textTransform: "none",
        fontWeight: 600,
        width: width,
        fontSize: "18px",
        borderWidth: "1px",
        borderRadius: "4px",
      }}
      startIcon={
        loading ? (
          <ClipLoader size={"22px"} color={"#36d7b7"} loading={loading} />
        ) : showIcon ? (
          <Avatar
            sx={{ width: "20px", height: "20px" }}
            src={iconPath}
            className="icon"
            variant="square"
          />
        ) : null
      }
      disabled={loading}
      onClick={handleClick}
    >
      {children}
    </Button>
  );
}
