import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import WifiOffIcon from "@mui/icons-material/WifiOff";

export const NotConnected = ({ handleReload, reloading }) => {
  return (
    <Box sx={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
      <div style={{ margin: "15px 0 15px 0" }}>
        <WifiOffIcon style={{ fontSize: 90 }} />
      </div>
      <Typography color="text.primary" sx={{ fontSize: "20px", mb: "15px" }}>
        You seems to be offline.
      </Typography>
      <Typography color="text.secondary" sx={{ fontSize: "14px" }}>
        Please connect to the internet or reload.
      </Typography>
      <Box sx={{ pt: "20px", textAlign: "center" }}>
        <LoadingButton variant="contained" onClick={() => handleReload()} loading={reloading}>
          RELOAD
        </LoadingButton>
      </Box>
    </Box>
  );
};
