import React from "react";
import { Step, StepLabel, Stepper } from "@mui/material";

export default function OnBoardingStepper2() {
  const steps = ["Login with Gmail", "Connect Calendar", "Connect Zoom"];

  return (
    <Stepper activeStep={1} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel
            sx={{ ".Mui-active > svg, .Mui-completed > svg": { color: "#14afd0 !important" } }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
