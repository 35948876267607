import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export const SpinnerComponent = () => {
  let nice_things_to_say = [
    "Remember to get up & stratch once in a while.",
    "Patience is a virtue, but taking a break is good for the soul.",
    "While you wait, take a deep breath and enjoy the moment.",
    "Good things come to those who wait, so relax and enjoy the journey.",
    "Take a break and enjoy the small things in life.",
    "Just like this page, great things take time to load.",
    "In the meantime, sit back, relax and enjoy the view.",
    "The wait is almost over, grab a coffee and enjoy the moment.",
    "Take a moment to reflect and plan your next move.",
    "Don't worry, the page is loading, and so is your future.",
    "Good things are worth the wait, trust the process.",
    "The page is loading, but so is your creativity.",
    "While you wait, take a walk, clear your mind, and come back refreshed.",
    "Time flies when you're having fun, enjoy the wait.",
    "Take this moment to appreciate the journey, not just the destination.",
    "The page is loading, and so is your success.",
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      <Box sx={{ justifyContent: "center", textAlign: "center" }}>
        {/* <img alt="No meetings" src="smil." width={150} /> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            pb: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              mx: "25px",
              mb: "15px",
              flexDirection: "column",
              flexWrap: "wrap",
              flexBasis: "100%",
              flex: 1,
            }}
          >
            <Typography color="text.primary" sx={{ fontSize: "20px", mb: "15px", mt: "20px" }}>
              {nice_things_to_say[Math.floor(Math.random() * nice_things_to_say.length)]}
            </Typography>

            <Typography color="text.secondary" sx={{ fontSize: "14px" }}>
              - Your friends in MeetPal
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              flexBasis: "100%",
              flex: 1,
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
