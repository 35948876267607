import React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { ClipLoader } from "react-spinners";

export default function OnBoardingButton({
  color = "primary",
  width = "80%",
  linkUrl = null,

  borderRadius = "0px",
  mb = 0,
  loading,
  handleClick,
  showIcon = true,
  iconPath = "/google-icon.png",
  children,
}) {
  return (
    <Button
      href={linkUrl}
      color={"secondary"}
      // @ts-ignore
      sx={{
        backgroundColor: "white",
        color: "black",
        boxShadow: "none",

        border: "1px solid",
        borderColor: "rgb(180, 196, 204)",
        borderWidth: "1px",

        maxWidth: "18.75rem",
        p: "0 0.75rem",
        m: "0.5rem 0",
        height: "3.75rem",

        marginBottom: mb,
        textTransform: "none",
        fontWeight: 600,
        width: width,
        fontSize: "18px",
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: "rgb(210, 220, 224)",
        },
      }}
      startIcon={
        loading ? (
          <ClipLoader size={"22px"} color={"#36d7b7"} loading={loading} />
        ) : showIcon ? (
          <Box
            sx={{
              ml: "0.75rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "2.25rem",
              height: "2.25rem",
              background: "white",
              borderRadius: "0.25rem",
            }}
          >
            <Avatar
              sx={{
                width: "1.65rem",
                height: "1.65rem",
                backgroundColor: "white",
                borderRadius: "0.25rem",
              }}
              src={iconPath}
              className="icon"
              variant="square"
            />
          </Box>
        ) : null
      }
      disabled={loading}
      onClick={handleClick}
    >
      <Typography sx={{ flexGrow: 1 }} fontWeight={500}>
        {children}
      </Typography>
    </Button>
  );
}
