// @ts-nocheck
/* globals FS */
import React, { useState, useEffect } from "react";
import { isSafari } from "react-device-detect";
import { isMobile } from "react-device-detect";
import {
  eraseCookieForUserAuthentication,
  getCookieForUserAuthentication,
  setCookieForUserAuthentication,
  eraseCookieForElectron,
  getCookieForElectron,
} from "../global/cookies";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useSearchParams } from "react-router-dom";
import { Grid, Box, Tooltip } from "@mui/material";
import { getProfile, getLatestReleases } from "global/api";
import { ClipLoader } from "react-spinners";
import ResponsiveAppBar from "components/navbar.component";
import DownloadDesktop from "components/home-1-download-desktop.component";
import DownloadMobile from "components/home-1-download-mobile.component";
import Participantsview from "components/meetpal-app.component";
import CloseIcon from "@mui/icons-material/Close";

export default function Home(props) {
  let [user, setUser] = useState(null);
  let [signoutLoading, setSignoutLoading] = useState(false);
  let [windowsUrl, setWindowsUrl] = useState(null);
  let [macUrl, setMacUrl] = useState(null);
  let [isLoad, setIsLoad] = useState(true);
  let [showInstallMessage, setShowInstallMessage] = useState(false);

  const [searchParams] = useSearchParams();

  const redirectToGoogleLogin = () => {
    // @ts-ignore
    window.location.href = `/login`;
  };

  const redirectToAcceptCalendar = () => {
    // @ts-ignore
    window.location.href = `/pre-calendar?session=${getCookieForUserAuthentication()}`;
  };

  const redirectToLoginWithError = () => {
    eraseCookieForUserAuthentication();
    // @ts-ignore
    window.location.href = `/login?login-error=1`;
  };

  const redirectToAuthValidate = () => {
    eraseCookieForElectron();
    // @ts-ignore
    window.location.href = `/auth/validate`;
  };

  // ALL THIS TO CHECK IF TO POPUP THE PWA APP (SAFARI only - https://www.netguru.com/blog/pwa-ios)
  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    console.log("userAgent: ", userAgent);
    return /iphone|ipad|ipod/.test(userAgent) && isSafari;
  };
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => "standalone" in window.navigator && window.navigator.standalone;
  // END OF THIS TO CHECK IF TO POPUP THE PWA APP (SAFARI only - https://www.netguru.com/blog/pwa-ios)

  function SmallWindow() {
    return (
      <>
        <ResponsiveAppBar
          user={user}
          photo_url={user.photo_url}
          signoutLoading={signoutLoading}
          setSignoutLoading={setSignoutLoading}
        />
        <DownloadMobile user={user}>
          <Box sx={{ overflowY: "scroll", width: "330px", height: "350px", border: "1px solid" }}>
            <Participantsview />
          </Box>
        </DownloadMobile>
        {showInstallMessage && (
          <Tooltip
            componentsProps={{
              popper: {
                sx: {
                  top: "100% !important",
                },
              },
              tooltip: {
                sx: {
                  bgcolor: "common.black",
                  "& .MuiTooltip-arrow": {
                    color: "common.black",
                  },
                },
              },
            }}
            open={true}
            arrow
            fontSize="30px"
            placement="top"
            title={
              <Grid sx={{ display: "flex" }}>
                <Grid
                  item
                  sx={{
                    mr: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AddBoxIcon fontSize="large" />
                </Grid>
                <Grid item>
                  <span style={{ fontSize: "14px" }}>
                    Install this webapp on your IPhone: Tap{" "}
                    <img src="/share-safari.png" width={16} alt="MeetPal" /> and then Add to Home
                    Screen.
                  </span>
                </Grid>
                <CloseIcon sx={{ float: "right" }} onClick={(e) => console.log(e)}></CloseIcon>
              </Grid>
            }
            disableInteractive
          >
            <Box> </Box>
          </Tooltip>
        )}
      </>
    );
  }

  function BigWindow() {
    return (
      <>
        <ResponsiveAppBar
          user={user}
          photo_url={user.photo_url}
          signoutLoading={signoutLoading}
          setSignoutLoading={setSignoutLoading}
        />
        <DownloadDesktop user={user} macUrl={macUrl} windowsUrl={windowsUrl}>
          <Box sx={{ overflowY: "scroll", width: "360px", height: "425px", border: "1px solid" }}>
            <Participantsview />
          </Box>
        </DownloadDesktop>
      </>
    );
  }

  const fetchData = async () => {
    const data = await getProfile()
      .then((res) => {
        setIsLoad(true);
        // console.log("PROFILE RESP: ", res);
        setUser(res);
        let isDeepLink = getCookieForElectron();

        if (res.status === "OK") {
          if (res.need_to_relogin) {
            redirectToLoginWithError();
          } else if ((res.cal_accepted || res.connected_with_microsoft) && isDeepLink) {
            // OPEN ELECTRON
            redirectToAuthValidate();
          } else if (!res.cal_accepted && !res.connected_with_microsoft) {
            redirectToAcceptCalendar();
          } else if (!res.zoom_installed) {
            // console.log("None");
            setIsLoad(false);
          } else {
            // good.
            setIsLoad(false);
          }
        } else {
          // guest
          redirectToGoogleLogin();
        }
      })
      .finally(() => {
        // console.log("done api");
      });
  };

  const fetchDownloadLinks = async () => {
    const data = await getLatestReleases();

    setWindowsUrl(data.windowsUrl);
    setMacUrl(data.macUrl);
  };

  useEffect(() => {
    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setShowInstallMessage(true);
    }

    if (searchParams.get("session")) {
      setCookieForUserAuthentication(searchParams.get("session"));
    }

    if (window.location.pathname != "/home") {
      let isDeepLink = getCookieForElectron();
      if (!isDeepLink) {
        window.location.href = `/home`;
      } else {
        eraseCookieForElectron();
        // @ts-ignore
        window.location.href = `/auth/validate`;
      }
    }

    fetchData();
    fetchDownloadLinks();
  }, []);

  return isLoad ? (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      component="main"
      sx={{ height: "100vh" }}
    >
      <ClipLoader color={"#36d7b7"} loading={isLoad} />
    </Grid>
  ) : isMobile ? (
    <SmallWindow user />
  ) : (
    <>
      <BigWindow />
    </>
  );
}
