import React from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";

export const Login = ({ relogin, handleRelogin }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          textAalign: "center",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "250px",
        }}
      >
        You seem to be logged-out.
      </Box>
      <Box
        sx={{
          display: "flex",
          textAalign: "center",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <LoadingButton variant="contained" onClick={() => handleRelogin()} loading={relogin}>
          Sign-in
        </LoadingButton>
      </Box>
    </>
  );
};
