// @ts-nocheck
/* globals FS */
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  eraseCookieForUserAuthentication,
  getCookieForUserAuthentication,
  setCookieForUserAuthentication,
  eraseCookieForElectron,
  getCookieForElectron,
} from "../global/cookies";
import { useSearchParams } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/material";
import { getProfile } from "global/api";
import { ClipLoader } from "react-spinners";
import ResponsiveAppBar from "components/navbar.component";

const REMOVE_ZOOM_PATH = "https://marketplace.zoom.us/user/installed";
const INSTALL_ZOOM_PATH = "https://zoom.meetpal.co/api/zoomapp/install";

export default function AuthValidateDeny(props) {
  let [deepLinkShowButton, setDeepLinkShowButton] = useState(false);
  let [signoutLoading, setSignoutLoading] = useState(false);

  let [user, setUser] = useState(null);
  let [isLoad, setIsLoad] = useState(true);
  let [installLoading, setInstallLoading] = useState(false);

  const [searchParams] = useSearchParams();

  if (searchParams.get("session")) {
    setCookieForUserAuthentication(searchParams.get("session"));
  }

  const handleDeepLinkClick = () => {
    setInstallLoading(!installLoading);
    eraseCookieForElectron();

    var myInterval = setInterval(() => {
      if (installLoading) {
        setInstallLoading((installLoading) => !installLoading);
      } else {
        setInstallLoading(false);
        clearInterval(myInterval);
        window.open(`meetpal://login?session=${getCookieForUserAuthentication()}`);
      }
    }, 2500);
  };

  const redirectToLoginWithError = () => {
    eraseCookieForUserAuthentication();
    // @ts-ignore
    window.location.href = `/login?login-error=1`;
  };

  const redirectToGoogleLogin = () => {
    // @ts-ignore
    window.location.href = `/login`;
  };

  const redirectToAcceptCalendar = () => {
    // @ts-ignore
    window.location.href = `/pre-calendar?session=${getCookieForUserAuthentication()}`;
  };

  const fetchData = async () => {
    const data = await getProfile()
      .then((res) => {
        setIsLoad(true);
        // console.log("PROFILE RESP: ", res);
        setUser(res);
        let isDeepLink = getCookieForElectron();

        if (res.status === "OK") {
          if (res.need_to_relogin) {
            redirectToLoginWithError();
          } else if ((res.cal_accepted || res.connected_with_microsoft) && isDeepLink) {
            // OPEN ELECTRON
            setDeepLinkShowButton(true);
            setIsLoad(false);
          } else if (!res.cal_accepted && !res.connected_with_microsoft) {
            redirectToAcceptCalendar();
          } else if (!res.zoom_installed) {
            // console.log("None");
            setIsLoad(false);
          } else {
            setIsLoad(false);
          }
        } else {
          // guest
          redirectToGoogleLogin();
        }
      })
      .finally(() => {
        // console.log("done api");
        // setIsLoad(false);
      });
  };

  useEffect(() => {
    eraseCookieForElectron();
    fetchData();
  }, []);

  return isLoad ? (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      component="main"
      sx={{ height: "100vh" }}
    >
      <ClipLoader color={"#36d7b7"} loading={isLoad} />
    </Grid>
  ) : (
    <>
      <ResponsiveAppBar
        user={user}
        photo_url={user.photo_url}
        signoutLoading={signoutLoading}
        setSignoutLoading={setSignoutLoading}
      />
      <Box maxWidth={"45%"} sx={{ margin: "auto", pt: "50px" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ margin: "auto" }}
        >
          <Grid item>
            <Box
              width={150}
              height={175}
              sx={{
                borderRadius: "5px",
                margin: "auto",
                backgroundImage: "url(/auth-validate-deny.png)",
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Grid>

          <Grid item>
            <Typography textAlign={"center"} variant="h4" fontWeight={800}>
              Request denied!
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
