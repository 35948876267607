import React from "react";
import Box from "@mui/material/Box";

export const NoMeetings = ({ period }) => {
  return (
    <Box sx={{ m: "25px", textAlign: "center" }}>
      <center>
        <div
          style={{
            // @ts-ignore
            "margin-bottom": "20px",
          }}
        >
          <img alt="No meetings" src="./no-meetings.png" width={150} />
        </div>
        {period == "today" || period == "tomorrow" ? (
          <div>
            <span>No meetings {period}</span>
          </div>
        ) : (
          <div>
            <span>No meetings were found that date</span>
          </div>
        )}
        {period === "today" ? (
          <div>
            <span style={{ color: "dimgray" }}>Enjoy your meetings free day</span>
          </div>
        ) : period === "tomorrow" ? (
          <div>
            <span style={{ color: "dimgray" }}>Time to make plans</span>
          </div>
        ) : (
          <div>
            <span style={{ color: "dimgray" }}>Hope you had fun!</span>
          </div>
        )}
      </center>
    </Box>
  );
};
