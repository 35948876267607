// @ts-nocheck
/* globals gtag */

import React, { useState, useEffect } from "react";
import {
  eraseCookieForUserAuthentication,
  getCookieForUserAuthentication,
  setCookieForUserAuthentication,
} from "../global/cookies";
import { useSearchParams } from "react-router-dom";
import OnBoardingTitle from "../components/on-boarding-title.component";
import OnBoardingText from "../components/on-boarding-text.component";
import OnBoardingButton from "components/on-boarding-button.component";
import OnBoardingContainer from "../components/on-boarding-container.component";
import { Grid } from "@mui/material";
import OnBoardingImage from "components/on-boarding-image.component";
import { getProfile } from "global/api";
import { Alert, Snackbar } from "@mui/material";
import { ClipLoader } from "react-spinners";
import OnBoardingStepper2 from "components/on-boarding-stepper-2.component";

const API_PATH = "https://dev.meetpal.co/api/v1";
// const API_PATH = "http://localhost:5004/api/v1";

export default function CalendarOnBoarding(props) {
  let imagePath = "/blue-connect-calendar-small.png";
  let [loading, setLoading] = useState(false);
  let [isLoad, setIsLoad] = useState(true);
  let [shouldGoDeeplink, setShouldGoDeeplink] = useState(false);
  const [openWarnning, setOpenWarnning] = useState(false);

  const [searchParams] = useSearchParams();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenWarnning(false);
  };

  const redirectToGoogleCalendar = () => {
    // @ts-ignore
    window.location.href = `${API_PATH}/calendar_redirect?session=${getCookieForUserAuthentication()}`;
  };

  const redirectToHome = () => {
    // @ts-ignore
    window.location.href = `/`;
  };

  const redirectToLoginWithError = () => {
    eraseCookieForUserAuthentication();
    // @ts-ignore
    window.location.href = `/login?login-error=1`;
  };

  const redirectToGoogleLogin = () => {
    // @ts-ignore
    window.location.href = `/login`;
  };

  const handleClick = () => {
    setLoading(!loading);
    redirectToGoogleCalendar();
  };

  const fetchData = async () => {
    const data = await getProfile()
      .then((res) => {
        // console.log("PROFILE RESP: ", res);
        if (res.status === "OK") {
          if (res.need_to_relogin) {
            redirectToLoginWithError();
          } else if (res.cal_accepted || res.connected_with_microsoft) {
            redirectToHome();
          } else {
            if (searchParams.get("msg")) {
              // for user clicked - back to app without permissions approval
              setIsLoad(false);
            } else {
              // redirect in the first time without showing the page
              redirectToGoogleCalendar();
            }
          }
        } else {
          // guest
          redirectToGoogleLogin();
        }
      })
      .finally(() => {
        // console.log("done api");
      });
  };

  useEffect(() => {
    if (searchParams.get("session")) {
      setCookieForUserAuthentication(searchParams.get("session"));
    }

    if (searchParams.get("msg")) {
      setOpenWarnning(true);
    }

    setIsLoad(true);

    fetchData();
  }, []);

  return isLoad ? (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      component="main"
      sx={{ height: "100vh" }}
    >
      <ClipLoader color={"#36d7b7"} loading={isLoad} />
    </Grid>
  ) : (
    <Grid container component="main" sx={{ height: "100vh", textAlign: "center" }}>
      <OnBoardingContainer imagePath={imagePath}>
        {openWarnning && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            open={openWarnning}
            autoHideDuration={4500}
            onClose={handleClose}
            key={"top left"}
          >
            <Alert severity="warning" sx={{ width: "100%" }}>
              Access denied. Please accept calendar permissions to continue 🙏.
            </Alert>
          </Snackbar>
        )}

        <OnBoardingTitle>Connect Calendar</OnBoardingTitle>
        <OnBoardingText mb={6}>
          Learn more about the people you are meeting by connecting your calendar
        </OnBoardingText>
        <OnBoardingButton handleClick={handleClick} loading={loading}>
          Grant Calendar Access
        </OnBoardingButton>
      </OnBoardingContainer>
      <OnBoardingImage isCal={true} imagePath={imagePath}>
        <OnBoardingStepper2 />
      </OnBoardingImage>
    </Grid>
  );
}
