// @ts-nocheck
/* globals FS */
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Typography from "@mui/material/Typography";
import OnBoardingTitle from "./on-boarding-title.component";
import HomeDownloadButton from "./home-download-button.component";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";

import { updateAppStatus } from "global/api";
import { Link } from "react-router-dom";

export default function DownloadDesktop({ user, macUrl, windowsUrl, children }) {
  let [installMacLoading, setInstallMacLoading] = useState(false);
  let [installWindowsLoading, setInstallWindowsLoading] = useState(false);
  let [demoexampleView, setDemoExampleView] = useState(false);
  let [timeToEndDemo, setTimeToEndDemo] = useState(30);

  const participantStyle = {
    padding: "6px",
  };

  useEffect(() => {
    // console.log("user: ", user);
  }, [demoexampleView]);

  const handleMacClicked = async () => {
    setInstallMacLoading(!installMacLoading);
    await updateAppStatus("MAC", "DOWNLOADED");

    var myInterval = setInterval(async () => {
      console.log("interval: ", myInterval, "installMacLoading: ", installMacLoading);

      if (installMacLoading) {
        setInstallMacLoading((installMacLoading) => !installMacLoading);
      } else {
        setInstallMacLoading(false);
        clearInterval(myInterval);

        if (!isMobile) {
          if (user.mac_installed) {
            // window.open(`meetpal://openApp`);

            // Send event that download triggered
            FS.event("Mac Open Clicked", { user: user });
          } else {
            // Send event that download triggered
            FS.event("Mac Download Clicked", { user: user });
          }
        }

        if (isMobile) {
          let alertMsg = "For the download, please use a desktop computer.";
          return alert(alertMsg);
        }
      }
    }, 1500);
  };

  const handleStartDemo = async () => {
    console.log("clickedddd");
    setDemoExampleView(true);

    var myInterval = setInterval(async () => {
      console.log("interval: ", myInterval, "installMacLoading: ", installMacLoading);
      setTimeToEndDemo((current) => {
        if (current <= 0) {
          setTimeToEndDemo(30);
          clearInterval(myInterval);
          setDemoExampleView(false);
        }

        return current - 1;
      });
    }, 1000);
  };

  const handleWindowsClicked = async () => {
    setInstallWindowsLoading(!installWindowsLoading);
    await updateAppStatus("WINDOWS", "DOWNLOADED");

    var myInterval = setInterval(async () => {
      console.log("interval: ", myInterval, "installWindowsLoading: ", installWindowsLoading);

      if (installWindowsLoading) {
        setInstallWindowsLoading((installWindowsLoading) => !installWindowsLoading);
      } else {
        setInstallWindowsLoading(false);
        clearInterval(myInterval);

        if (!isMobile) {
          if (user.windows_installed) {
            // window.open(`meetpal://openApp`);

            // Send event that download triggered
            FS.event("Windows Open Clicked", { user: user });
          } else {
            // Send event that download triggered
            FS.event("Windows Download Clicked", { user: user });
          }
        }

        if (isMobile) {
          let alertMsg = "For the download, please use a desktop computer.";
          return alert(alertMsg);
        }
      }
    }, 1500);
  };

  return (
    <Grid container direction={"column"} alignItems={"center"} component="main" marginTop={"20px"}>
      <OnBoardingTitle>Hey {user.display_name}, Welcome! </OnBoardingTitle>
      {demoexampleView && (
        <>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", mb: 2 }}>
            <Typography sx={{ mb: 0 }} fontSize={16} fontWeight={400} gutterBottom>
              Demo Time! the session will end in:{" "}
              <Typography
                sx={{ mb: 0, color: "orange", fontWeight: 700 }}
                fontSize={16}
                fontWeight={400}
                gutterBottom
                component="span"
              >
                {timeToEndDemo}sec
              </Typography>
            </Typography>

            <Typography sx={{ mb: 0 }} fontSize={16} fontWeight={400} gutterBottom>
              For the desktop app, click here:{" "}
              <HomeDownloadButton
                variant="outlined"
                width={107}
                py={"0px"}
                linkUrl={macUrl}
                showIcon={false}
                loading={installMacLoading}
                handleClick={handleMacClicked}
                sx={{
                  ml: 1,
                  borderRadius: "5px",
                  mx: "auto",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: (t) =>
                    t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {"Download"}
              </HomeDownloadButton>
            </Typography>
          </Box>
          {children}
        </>
      )}
      {!demoexampleView && (
        <>
          <Typography fontSize={22} fontWeight={400} gutterBottom>
            Select the platform that suits you best.
          </Typography>

          <TableContainer sx={{ display: "flex", justifyContent: "center" }}>
            <Table
              sx={{
                borderSpacing: "0px 0px",
                borderCollapse: "separate",
                maxWidth: "600px",
                [`& .${tableCellClasses.root}`]: { borderBottom: "none" },
              }}
              size="small"
            >
              <TableHead></TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ ...participantStyle, pb: "25px" }}>
                    <Box
                      width={150}
                      height={150}
                      sx={{
                        borderRadius: "5px",
                        margin: "auto",
                        backgroundImage: "url(/apple-logo.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ ...participantStyle, pb: "25px" }}>
                    <Box
                      width={150}
                      height={150}
                      sx={{
                        borderRadius: "5px",
                        mx: "auto",
                        my: "0px",
                        backgroundImage: "url(/windows-logo.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell sx={{ ...participantStyle, textAlign: "center" }}>
                    <HomeDownloadButton
                      width={150}
                      height={150}
                      linkUrl={macUrl}
                      iconPath={"download.svg"}
                      showIcon={true}
                      loading={installMacLoading}
                      handleClick={handleMacClicked}
                      sx={{
                        borderRadius: "5px",
                        margin: "auto",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      {"Mac"}
                    </HomeDownloadButton>
                  </TableCell>
                  <TableCell sx={{ ...participantStyle, textAlign: "center" }}>
                    <HomeDownloadButton
                      width={150}
                      height={150}
                      linkUrl={windowsUrl}
                      iconPath={"download.svg"}
                      loading={installWindowsLoading}
                      handleClick={handleWindowsClicked}
                      sx={{
                        borderRadius: "5px",
                        margin: "auto",
                        backgroundRepeat: "no-repeat",
                        backgroundColor: (t) =>
                          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      {user.windows_installed ? "Open App" : "Windows"}
                    </HomeDownloadButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
            <Typography
              sx={{ fontStyle: "italic", mb: 0 }}
              fontSize={16}
              fontWeight={400}
              gutterBottom
            >
              To see a limited demo in the browser click here:{" "}
            </Typography>
            <Button onClick={handleStartDemo}>Try a web demo</Button>
          </Box>
        </>
      )}

      <Grid item sx={!demoexampleView ? { position: "absolute", top: "92vh" } : { mt: "20px" }}>
        <Typography variant="subtitle1" fontSize={"14px"} color={"#6c6c6c"} fontWeight={100}>
          By downloading and using MeetPal, you agree to the license terms and privacy statement.
        </Typography>
      </Grid>
    </Grid>
  );
}
