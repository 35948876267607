import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  eraseCookieForElectron,
  getCookieForElectron,
  getCookieForUserAuthentication,
  setCookieForElectron,
  setCookieForUserAuthentication,
} from "../global/cookies";
import { useSearchParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import OnBoardingTitle from "../components/on-boarding-title.component";
import OnBoardingText from "../components/on-boarding-text.component";
import OnBoardingContainer from "../components/on-boarding-container.component";
import OnBoardingImage from "components/on-boarding-image.component";
import { getProfile } from "global/api";
import { Alert, Snackbar } from "@mui/material";
import OnBoardingButton from "components/on-boarding-button.component";
import { isMobile } from "react-device-detect";
import { Warning } from "@mui/icons-material";

const API_PATH = "https://dev.meetpal.co/api/v1";
// const API_PATH = "http://localhost:5004/api/v1";

export default function GoogleOnBoarding(props) {
  let imagePath = "/onboarding-first-image.png";

  let [installLoading, setInstallLoading] = useState(false);

  let [loadingGoogle, setLoadingGoogle] = useState(false);
  let [loadingMicrosoft, setLoadingMicrosoft] = useState(false);

  let [isLoad, setIsLoad] = useState(true);
  const [openWarnning, setOpenWarnning] = useState(false);
  const [warnningMsg, setWarnningMsg] = useState("User is not authorized. Please login again.");

  const [searchParams] = useSearchParams();

  if (searchParams.get("session")) {
    setCookieForUserAuthentication(searchParams.get("session"));
  }

  if (searchParams.get("desktop")) {
    setCookieForElectron();
  } else {
    eraseCookieForElectron();
  }

  const redirectToGoogleLogin = () => {
    // @ts-ignore
    window.location.href = `${API_PATH}/google_redirect`;
  };

  const redirectToMicrosoftLogin = () => {
    // @ts-ignore
    window.location.href = `${API_PATH}/microsoft_redirect`;
  };

  const handleGoogleClick = () => {
    setLoadingGoogle(!loadingGoogle);
    redirectToGoogleLogin();
  };

  const handleMicrosoftClick = () => {
    setLoadingMicrosoft(!loadingMicrosoft);
    redirectToMicrosoftLogin();
  };

  const redirectToHome = () => {
    // @ts-ignore
    window.location.href = `/`;
  };

  const redirectToAuthValidate = () => {
    // @ts-ignore
    window.location.href = `/auth/validate`;
  };

  useEffect(() => {
    setIsLoad(true);

    if (searchParams.get("login-error") === "1") {
      setOpenWarnning(true);
    }

    if (searchParams.get("msg") === "access_denied") {
      setOpenWarnning(true);
      setWarnningMsg("Access denied. Please accept calendar permissions to continue 🙏.");
    }

    getProfile()
      .then((res) => {
        let isDeepLink = searchParams.get("desktop");

        // console.log("PROFILE RESP: ", res);
        if ((res.cal_accepted || res.connected_with_microsoft) && isDeepLink) {
          // after puting the electron cookie will redirect to the validate
          redirectToAuthValidate();
        } else if ((res.cal_accepted || res.connected_with_microsoft) && res.zoom_installed) {
          redirectToHome();
        } else if (res.cal_accepted || res.connected_with_microsoft) {
          redirectToHome();
        } else {
          setIsLoad(false);
        }
      })
      .finally(() => {
        // console.log("done api");
      });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenWarnning(false);
  };

  return isLoad ? (
    <Grid
      container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      component="main"
      sx={{ height: "100vh" }}
    >
      <ClipLoader color={"#36d7b7"} loading={isLoad} />
    </Grid>
  ) : (
    <Grid container component="main" sx={{ height: "100vh", textAlign: "center" }}>
      <OnBoardingContainer imagePath={imagePath}>
        {openWarnning && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            open={openWarnning}
            autoHideDuration={4500}
            onClose={handleClose}
            key={"top center"}
          >
            <Alert severity="error" sx={{ width: "100%" }}>
              {warnningMsg}
            </Alert>
          </Snackbar>
        )}

        <OnBoardingTitle>Sign in/up</OnBoardingTitle>

        <OnBoardingText>
          Tool that drives engagement and productivity before, during, and after every meeting
        </OnBoardingText>

        <OnBoardingButton
          width={!isMobile ? "70%" : "100%"}
          handleClick={handleGoogleClick}
          loading={loadingGoogle}
        >
          Sign up with Google
        </OnBoardingButton>

        <OnBoardingButton
          iconPath="/microsoft-logo.png"
          width={!isMobile ? "70%" : "100%"}
          handleClick={handleMicrosoftClick}
          loading={loadingMicrosoft}
        >
          Sign up with Microsoft
        </OnBoardingButton>
      </OnBoardingContainer>

      <OnBoardingImage imagePath={imagePath} />
    </Grid>
  );
}
