import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import ReplayIcon from "@mui/icons-material/Replay";
import { styled } from "@mui/material/styles";

const CustomLoadingButton = styled(LoadingButton)({
  [`&.MuiLoadingButton-root`]: {
    border: 0,
    // float: "right",
    minWidth: "28px",
    color: "black",
  },
  [`&.MuiLoadingButton-root:hover`]: {
    border: 0,
  },
});

export const ReloadButton = ({ reloading, handleReload }) => {
  return (
    <CustomLoadingButton size="small" onClick={handleReload} loading={reloading} variant="outlined">
      <ReplayIcon fontSize="small" />{" "}
    </CustomLoadingButton>
  );
};
//   sx={{ , minWidth: "30px" }}
