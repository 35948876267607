import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { blue, red, green, grey, lightBlue } from "@mui/material/colors";
import Routes from "./Routes";
import React from "react";

const theme = createTheme({
  typography: {
    fontFamily: `Poppins, Gotham, "Proxima Nova", "Avenir Next", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, sans-serif`,
  },
  palette: {
    secondary: {
      main: "#000",
    },
    primary: {
      main: "#0072b1",
    },
    info: {
      main: grey[800],
    },
    success: {
      main: lightBlue[600],
    },
    error: {
      main: red[500],
    },
    green: { main: green[500] },
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <main>
        {/* <FirebaseContext.Provider value={new Firebase()}> */}
        <div className="App">
          <Routes />
        </div>
        {/* </FirebaseContext.Provider> */}
      </main>
    </Router>
  </ThemeProvider>
);

export default App;
